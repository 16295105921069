<style lang="scss"></style>

<template>
  <div>
    <validation-observer ref="observer" v-slot="{ handleSubmit }">
      <b-form @submit.stop.prevent="handleSubmit(updatePossession)">
        <section class="mb-2">
          <div>
            <label>Title *</label>
            <validation-provider
              v-slot="validationContext"
              :rules="{ required: true, min: 2 }"
              name="Title"
            >
              <b-form-input
                v-model="possession.title"
                :state="getValidationState(validationContext)"
                class="mb-1"
                placeholder="Enter title"
              />

              <b-form-invalid-feedback>{{
                validationContext.errors[0]
              }}</b-form-invalid-feedback>
            </validation-provider>
          </div>

          <div class="mb-1">
            <label>Description</label>
            <b-form-input
              v-model="possession.description"
              placeholder="Enter description"
            />
          </div>

          <div class="mb-1">
            <label>Colour</label>
            <b-form-input
              v-model="possession.colour"
              placeholder="Enter colour"
            />
          </div>

          <div class="mb-1">
            <label>Origin</label>
            <b-form-input
              v-model="possession.origin"
              placeholder="Enter origin"
            />
          </div>

          <div class="mb-1">
            <label>Cost</label>
            <b-form-input
              v-model="possession.cost_of_item"
              type="number"
              step="0.01"
              placeholder="Enter cost of item"
            />
          </div>

          <div class="mb-1">
            <label>Is Prohibited</label>
            <b-form-select v-model="possession.is_prohibited">
              <b-form-select-option selected value="false"
                >No</b-form-select-option
              >
              <b-form-select-option value="true">Yes</b-form-select-option>
            </b-form-select>
          </div>

          <div class="mb-1">
            <label>Is Risk</label>
            <b-form-select v-model="possession.is_risk">
              <b-form-select-option selected value="false"
                >No</b-form-select-option
              >
              <b-form-select-option value="true">Yes</b-form-select-option>
            </b-form-select>
          </div>

          <div class="mb-1">
            <label>Owner</label>
            <owner-search-select
              :selected="selectedOwner"
              @change="updateSelectedOwner"
            />
          </div>

          <div class="mb-2">
            <label>Category *</label>
            <possession-category-search-select
              :selected="selectedCategory"
              @change="updateSelectedCategory"
            />
          </div>

          <div class="mb-1">
            <label>Sub Category *</label>

            <possession-category-search-select
              :selected="selectedSubcategory"
              :parent="selectedCategory"
              @change="updateSelectedSubcategory"
            />
          </div>

          <div class="mb-1">
            <label>Location *</label>
            <location-search-select
              :selected="selectedLocation"
              @change="updateSelectedLocation"
            />
          </div>
        </section>

        <section class="d-inline-flex full-width mt-2">
          <div class="ui-spacer" />
          <b-button class="mr-2" @click="$emit('close', true)">Cancel</b-button>
          <b-button type="submit" variant="primary" :disabled="!valid()"
            >Update possession</b-button
          >
        </section>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import PossessionsService from "@/services/PossessionsService";
import helperService from "@/services/HelperService";
import LocationSearchSelect from "@/components/input/PaginatedSearchSelect/LocationSearchSelect.vue";
import OwnerSearchSelect from "@/components/input/PaginatedSearchSelect/OwnerSearchSelect.vue";
import PossessionCategorySearchSelect from "@/components/input/PaginatedSearchSelect/PossessionCategorySearchSelect.vue";

export default {
  components: {
    PossessionCategorySearchSelect,
    OwnerSearchSelect,
    LocationSearchSelect,
  },
  props: {
    targetPossession: {
      type: Object,
      required: true,
    },
  },
  emits: ["close", "refresh"],
  data() {
    return {
      selectedLocation: null,
      selectedOwner: null,
      selectedCategory: null,
      selectedSubcategory: null,
      possession: { ...this.targetPossession },
    };
  },
  mounted() {
    this.selectedLocation = this.$props.targetPossession.location;
    this.selectedOwner = this.$props.targetPossession.owner;
    this.selectedCategory = this.$props.targetPossession.category;
    this.selectedSubcategory = this.$props.targetPossession.subcategory;
  },
  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    valid() {
      if (this.possession.title.length <= 0) {
        return false;
      }
      if (!this.possession.item_category_id) {
        return false;
      }
      if (!this.possession.item_sub_category_id) {
        return false;
      }
      if (!this.possession.location_id) {
        return false;
      }

      return true;
    },
    async updatePossession() {
      try {
        // Format data
        this.possession.title = this.possession.title.trim();
        this.possession.cost_of_item = parseFloat(this.possession.cost_of_item);
        this.possession.is_risk = this.possession.is_risk === "true";
        this.possession.is_prohibited =
          this.possession.is_prohibited === "true";

        // If max_items is empty, set it to null
        if (!this.possession.max_items) {
          this.possession.max_items = null;
        }

        await PossessionsService.updatePossession(
          this.possession.id,
          this.possession
        );

        this.$emit("refresh");
        this.$emit("close", true);
      } catch (err) {
        helperService.showNotfyErr(
          this.$toast,
          err,
          "Could not update possession, please refresh and try again"
        );
      }
    },
    updateSelectedLocation(location) {
      this.selectedLocation = location;
      if (!this.selectedLocation) {
        this.possession.location_id = null;
      } else {
        this.possession.location_id = this.selectedLocation.id;
      }
    },
    updateSelectedOwner(owner) {
      this.selectedOwner = owner;
      if (!this.selectedOwner) {
        this.possession.owner_uid = null;
      } else {
        this.possession.owner_uid = this.selectedOwner.uid;
      }
    },
    updateSelectedCategory(category) {
      if (this.selectedCategory !== category) {
        this.selectedSubcategory = null;
        this.possession.sub_category_id = null;
      }
      this.selectedCategory = category;
      if (!this.selectedCategory) {
        this.possession.category_id = null;
      } else {
        this.possession.category_id = this.selectedCategory.id;
      }
    },
    updateSelectedSubcategory(category) {
      this.selectedSubcategory = category;
      if (!this.selectedSubcategory) {
        this.possession.item_sub_category_id = null;
      } else {
        this.possession.item_sub_category_id = this.selectedSubcategory.id;
      }
    }
  },
};
</script>
