<template>
  <paginated-search-select
    :selected="selectedCategory"
    :get-label="getLabel"
    :options="categories"
    :template="template"
    :disabled="disabled"
    placeholder="Search categories..."
    @change="updateSelectedCategory"
    @change-search="updateCategoryFilter"
  />
</template>

<script>
import PossessionCategoriesService from "@/services/PossessionCategoriesService";
import ItemNameTemplate from "@/components/input/CAutoCompleteTemplates/ItemNameTemplate.vue";
import PaginatedSearchSelect from "@/components/input/PaginatedSearchSelect/PaginatedSearchSelect.vue";

export default {
  name: "PossessionCategorySearchSelect",
  components: { PaginatedSearchSelect },
  props: {
    selected: {
      type: Object,
      default: null,
    },
    parent: {
      type: Object,
      default: undefined,
    }
  },
  data() {
    return {
      loading: true,
      selectedCategory: this.$props.selected,
      categories: [],
      categoryFilters: {
        page: 0,
        page_size: 15,
        search: "",
        parent_id: this.$props.parent?.id.toString() || "0",
      },
      template: ItemNameTemplate,
      disabled: false,
    }
  },
  watch: {
    loading: {
      immediate: true,
      handler(newVal) {
        if(newVal) {
          this.$emit('loading');
        } else {
          this.$emit('loaded');
        }
      }
    },
    selected: {
      deep: true,
      handler() {
        this.selectedCategory = this.$props.selected;
      }
    },
    parent: {
      deep: true,
      handler() {
        if (this.$props.parent === null) {
          this.disabled = true;
          this.categoryFilters.parent_id = "0";
        } else {
          this.disabled = false;
          this.categoryFilters.parent_id = this.$props.parent.id.toString();
          this.getMoreCategories(1);
        }
      }
    }
  },
  mounted() {
    this.getCategories();

    if (this.$props.parent === null) {
      this.disabled = true;
    }
  },
  methods: {
    getMoreCategories(page) {
      this.categoryFilters.page = page - 1;
      this.getCategories();
    },
    async getCategories() {
      this.loading = true;
      try {
        const res = await PossessionCategoriesService.listCategories(this.categoryFilters);
        this.categories = res.data.data;
      } catch (err) {
        const res = err.response;
        let errorText = "Could not get categories, please refresh and try again";

        if (res && res.data.error) {
          errorText = res.data.error;
        }

        this.$toast.error(errorText, {
          toastClassName: ["toast-std", "warning-toast"],
        });
      } finally {
        this.loading = false;
      }
    },
    getLabel(category) {
      if (!category) return "";
      return category.name;
    },
    updateSelectedCategory(category) {
      this.$emit("change", category);
    },
    updateCategoryFilter(search) {
      this.categoryFilters.search = search;
      this.getMoreCategories(1);
    },
  },
};
</script>

<style scoped lang="scss"></style>
