<template>
  <paginated-search-select
    :selected="selectedLocation"
    :get-label="getLabel"
    :options="locations"
    :template="template"
    placeholder="Search locations..."
    @change="updateSelectedLocation"
    @change-search="updateLocationFilter"
  />
</template>

<script>
import LocationsService from "@/services/LocationsService";
import ItemNameTemplate from "@/components/input/CAutoCompleteTemplates/ItemNameTemplate.vue";
import PaginatedSearchSelect from "@/components/input/PaginatedSearchSelect/PaginatedSearchSelect.vue";

export default {
  name: "LocationSearchSelect",
  components: { PaginatedSearchSelect },
  props: {
    selected: {
      type: Object,
      default: null,
    }
  },
  data() {
    return {
      loading: true,
      selectedLocation: this.$props.selected,
      locations: [],
      locationFilters: {
        page: 0,
        page_size: 15,
        search: "",
      },
      template: ItemNameTemplate,
    }
  },
  watch: {
    loading: {
      immediate: true,
      handler(newVal) {
        if(newVal) {
          this.$emit('loading');
        } else {
          this.$emit('loaded');
        }
      }
    },
    selected: {
      deep: true,
      handler() {
        this.selectedLocation = this.$props.selected;
      }
    },
  },
  mounted() {
    this.getLocations();
  },
  methods: {
    getMoreLocations(page) {
      this.locationFilters.page = page - 1;
      this.getLocations();
    },
    async getLocations() {
      this.loading = true;
      try {
        const res = await LocationsService.getLocations(this.locationFilters);
        this.locations = res.data.data;
      } catch (err) {
        const res = err.response;
        let errorText = "Could not get locations, please refresh and try again";

        if (res && res.data.error) {
          errorText = res.data.error;
        }

        this.$toast.error(errorText, {
          toastClassName: ["toast-std", "warning-toast"],
        });
      } finally {
        this.loading = false;
      }
    },
    getLabel(location) {
      if (!location) return "";
      return location.name;
    },
    updateSelectedLocation(location) {
      this.$emit("change", location);
    },
    updateLocationFilter(search) {
      this.locationFilters.search = search;
      this.getMoreLocations(1);
    },
  },
};
</script>

<style scoped lang="scss"></style>
