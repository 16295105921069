<template>
  <paginated-search-select
    :selected="selectedOwner"
    :get-label="getLabel"
    :options="owners"
    :template="template"
    placeholder="Search owners..."
    @change="updateSelectedOwner"
    @change-search="updateOwnerFilter"
  />
</template>

<script>
import OwnersService from "@/services/OwnersService";
import OwnerTemplate from "@/components/input/CAutoCompleteTemplates/OwnerTemplate.vue";
import PaginatedSearchSelect from "@/components/input/PaginatedSearchSelect/PaginatedSearchSelect.vue";

export default {
  name: "OwnerSearchSelect",
  components: { PaginatedSearchSelect },
  props: {
    selected: {
      type: Object,
      default: null,
    }
  },
  data() {
    return {
      loading: true,
      selectedOwner: this.$props.selected,
      owners: [],
      ownerFilters: {
        page: 0,
        page_size: 15,
        search: "",
      },
      template: OwnerTemplate,
    }
  },
  watch: {
    loading: {
      immediate: true,
      handler(newVal) {
        if(newVal) {
          this.$emit('loading');
        } else {
          this.$emit('loaded');
        }
      }
    },
    selected: {
      deep: true,
      handler() {
        this.selectedOwner = this.$props.selected;
      }
    },
  },
  mounted() {
    this.getOwners();
  },
  methods: {
    getMoreOwners(page) {
      this.ownerFilters.page = page - 1;
      this.getOwners();
    },
    async getOwners() {
      this.loading = true;
      try {
        const res = await OwnersService.getOwners(this.ownerFilters);
        this.owners = res.data.data;
      } catch (err) {
        const res = err.response;
        let errorText = "Could not get owners, please refresh and try again";

        if (res && res.data.error) {
          errorText = res.data.error;
        }

        this.$toast.error(errorText, {
          toastClassName: ["toast-std", "warning-toast"],
        });
      } finally {
        this.loading = false;
      }
    },
    getLabel(owner) {
      if (!owner) return "";
      return owner.identifier;
    },
    updateSelectedOwner(owner) {
      this.$emit("change", owner);
    },
    updateOwnerFilter(search) {
      this.ownerFilters.search = search;
      this.getMoreOwners(1);
    },
  },
};
</script>

<style scoped lang="scss"></style>
